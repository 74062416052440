import React from 'react';

import { TwitterSvg, YouTubeSvg, TwitchSvg, DiscordSvg } from 'components';

type Props = {
  platform: string
}

const SocialButton: React.FC<Props> = ({ platform }) => {
  const platformLinks: any = {
    'twitter': {href: 'https://twitter.com/FiveGank', title: 'Follow Me on Twitter for Streaming updates'},
    'youtube': {href: 'https://www.youtube.com/channel/UConzlHuhDnegOdSwgNL3cow', title: 'Subscribe to FiveGank content'},
    'twitch': {href: 'https://twitch.tv/FiveGank', title: 'Watch FiveGank on Twitch'},
    'discord': {href: 'https://discordapp.com/invite/fyhtTYR', title: 'Join the conversation with FiveGank on Discord'}
  };
  const Components = (platform: string) => {
    switch (platform) {
      case 'twitter':
        return <TwitterSvg className="w-4 h-4" />;
      case 'youtube':
        return <YouTubeSvg className="w-4 h-4" />;
      case 'twitch':
        return <TwitchSvg className="w-4 h-4" />;
      case 'discord':
        return <DiscordSvg className="w-4 h-4" />;
    }
  }
  return (
    <a {...platformLinks[platform]} className={`social-button ${platform}-button hover:bg-white`} target="_blank">
      { Components(platform) }
    </a>
  );
};

export default SocialButton;