import React from 'react';
import { SocialButton } from 'components';

const Landing = (props: any) => (
  <div className="text-center">
    <header className="flex flex-col align-center justify-center h-screen mx-auto w-full md:w-1/2 text-white">
      <img src="/images/logo-512.svg" alt="logo" />
      <div className="flex flex-row w-full justify-center align-center mt-12">
        <SocialButton platform='twitter' />
        <SocialButton platform='twitch' />
        <SocialButton platform='youtube' />
        <SocialButton platform='discord' />
      </div>
    </header>
  </div>
)

export default Landing;