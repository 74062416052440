import React from 'react';

const DiscordSvg = ({ className }: any) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fab"
    data-icon="twitch"
    className={`svg-inline--fa fa-twitch fa-w-16 ${className}`}
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path
      fill="currentColor"
      d="M297.216,243.2 C297.216,258.816 285.696,271.616 271.104,271.616 C256.768,271.616 244.992,258.816 244.992,243.2 C244.992,227.584 256.512,214.784 271.104,214.784 C285.696,214.784 297.216,227.584 297.216,243.2 Z M177.664,214.784 C163.072,214.784 151.552,227.584 151.552,243.2 C151.552,258.816 163.328,271.616 177.664,271.616 C192.256,271.616 203.776,258.816 203.776,243.2 C204.032,227.584 192.256,214.784 177.664,214.784 Z M448,52.736 L448,512 C383.506,455.006 404.132,473.872 329.216,404.224 L342.784,451.584 L52.48,451.584 C23.552,451.584 0,428.032 0,398.848 L0,52.736 C0,23.552 23.552,0 52.48,0 L395.52,0 C424.448,0 448,23.552 448,52.736 Z M375.04,295.424 C375.04,212.992 338.176,146.176 338.176,146.176 C301.312,118.528 266.24,119.293873 266.24,119.293873 L262.656,123.392 C306.176,136.704 326.4,155.904 326.4,155.904 C265.589,122.575 194.156,122.569 135.168,148.48 C125.696,152.832 120.064,155.904 120.064,155.904 C120.064,155.904 141.312,135.68 187.392,122.368 L184.832,119.293873 C184.832,119.293873 149.76,118.528 112.896,146.176 C112.896,146.176 76.032,212.992 76.032,295.424 C76.032,295.424 97.536,332.544 154.112,334.336 C154.112,334.336 163.584,322.816 171.264,313.088 C138.752,303.36 126.464,282.88 126.464,282.88 C130.23,285.516 136.44,288.933 136.96,289.28 C180.17,313.478 241.548,321.406 296.704,298.24 C305.664,294.912 315.648,290.048 326.144,283.136 C326.144,283.136 313.344,304.128 279.808,313.6 C287.488,323.328 296.704,334.336 296.704,334.336 C353.28,332.544 375.04,295.424 375.04,295.424 L375.04,295.424 Z"
    ></path>
  </svg>
);

export default DiscordSvg;
